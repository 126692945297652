<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['organizations'])" />
  <div v-else>
    <div class="d-flex justify-content-between mb-4">
      <h1 class="h2" v-if="organization">{{ organization.name }} Residents</h1>
      <ResidentImport :organizationId="this.organizationId" />
    </div>
    <TableSkeleton v-if="residents === null" />
    <table class="table table-hover mb-5" v-else>
      <thead class="thead-light">
        <tr>
          <th scope="col">Name</th>
          <th scope="col" class="text-right">Birthday</th>
          <th scope="col" class="text-right">Room number</th>
          <th scope="col" class="text-right">This Month Spending</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(resident, i) in residents" :key="i">
          <td>{{ formatResidentName({ resident }) }}</td>
          <td class="text-right">
            <span v-if="resident.birthday">{{
              `${resident.birthday.substr(8, 2)}.${resident.birthday.substr(5, 2)}.${resident.birthday.substr(0, 4)}`
            }}</span>
          </td>
          <td class="text-right">
            {{ resident.roomNumber }}
          </td>
          <td class="text-right">
            {{ formatCurrency(resident.thisMonthSpending) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ResidentImport from '@/components/ResidentImport'
import { db } from '@/shared/firebase'
import TableSkeleton from '@/components/TableSkeleton'

export default {
  components: { ResidentImport, TableSkeleton },
  data() {
    return {
      residents: null,
      residentsSubscription: null
    }
  },
  created() {
    this.residentsSubscription = db
      .collection('residents')
      .where('organizations', 'array-contains', this.organizationId)
      .onSnapshot(snapshot => {
        this.residents = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        })
      })
  },
  computed: {
    organizationId() {
      return this.$route.params.organizationId
    },
    organization() {
      return _.find(this.$store.state.organizations, { id: this.organizationId })
    }
  }
}
</script>
