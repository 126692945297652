<template>
  <div>
    <div>
      <label for="file-upload" class="btn btn-outline-primary mb-0">
        Import Residents
      </label>
      <input id="file-upload" type="file" class="d-none" @click="clearRef" @change="parseCSV" ref="fileInput" />
    </div>
    <Layer v-if="showPreview" title="Import Residents" @close="clearImport">
      <ul v-if="livingAreasWithResidents" class="residentslist">
        <li v-for="(residents, key) in livingAreasWithResidents" :key="key">
          <div class="residentslist__livingarea-title">{{ key }}</div>
          <ul>
            <li v-for="(resident, index) in residents" :key="index">
              <small v-if="resident.gender === 'female'">Frau</small>
              <small v-if="resident.gender === 'male'">Herr</small>
              <div>
                {{ resident.firstName }} {{ resident.lastName }}, {{ resident.roomNumber || 'no room' }}
                <span v-if="resident.birthday">
                  ({{
                    `${resident.birthday.substr(8, 2)}.${resident.birthday.substr(5, 2)}.${resident.birthday.substr(
                      0,
                      4
                    )}`
                  }})</span
                >
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="d-flex justify-content-end p-3">
        <button class="btn btn-primary" @click="confirmImport" :disabled="importPending">
          {{ importPending ? 'Importing...' : `Import ${residents.length} Residents` }}
        </button>
      </div>
    </Layer>
  </div>
</template>

<script>
import Layer from '@/shared/Layer'
import { db, fb } from '@/shared/firebase'
import multiBatch from '@/shared/multiBatch'

import Papa from 'papaparse'

export default {
  components: { Layer },
  props: ['organizationId'],
  data() {
    return {
      results: null,
      showPreview: false,
      importPending: false
    }
  },
  computed: {
    residents() {
      return this.results.data ?? []
    },
    livingAreasWithResidents() {
      return _.groupBy(this.residents, 'livingArea')
    }
  },
  methods: {
    clearRef() {
      this.$refs.fileInput.value = null
    },
    parseCSV() {
      const fileInput = this.$refs.fileInput
      const importedAt = new Date()
      Papa.parse(fileInput.files[0], {
        header: true,
        encoding: 'UTF-8',
        complete: results => {
          this.showPreview = true
          results.data.forEach((entry, i) => {
            if (entry.lastName) {
              results.data[i] = {
                ...entry,
                firstName: entry.firstName.trim(),
                lastName: entry.lastName.trim(),
                importedAt
              }
              if (entry.birthday) {
                results.data[i].birthday = `${entry.birthday.substr(6, 4)}-${entry.birthday.substr(
                  3,
                  2
                )}-${entry.birthday.substr(0, 2)}`
              }
            }
          })
          this.results = results
        }
      })
    },
    confirmImport() {
      this.importPending = true
      const batch = new multiBatch()
      const organizations = []
      organizations.push(this.organizationId)
      this.results.data.forEach(resident => {
        const residentRef = db.collection('residents').doc()
        batch.set(residentRef, {
          ...resident,
          organizations,
          createdAt: fb.firestore.FieldValue.serverTimestamp()
        })
      })
      batch.commit().then(() => {
        this.clearImport()
        setTimeout(() => {
          alert('Import completed')
        }, 200)
      })
    },
    clearImport() {
      this.results = null
      this.showPreview = false
      this.importPending = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.residentslist {
  @extend %cleanlist;

  &__livingarea-title {
    background: #333;
    color: #fff;
    padding: 0.5rem 1rem;
  }

  ul {
    @extend %cleanlist;

    li {
      @extend %cleanlist;
      padding: 0.5rem 1rem;
      border-bottom: 1px #ddd solid;
    }
  }
}
</style>
